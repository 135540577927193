<template>
  <v-container id="crud" fluid tag="section">

      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="mdi-menu-down">
                Filtros
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <hr />
                <br />
                <v-row>
                  <v-col cols="12" sm="2" v-if="this.$can(['admin_general'])">
                    <v-text-field
                      v-model="puntoVenta"
                      label="Punto de Venta"
                      single-line hide-details
                      outlined
                      clearable
                      filled
                      dense
                      rounded
                      class="filtros-trans"
                      @click:append="clearField('ref_pedido')"
                      ></v-text-field>

                  </v-col>



                  <v-col cols="12" sm="2">
                    <v-menu
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedStartDate"
                          label="Fecha inicio"
                          single-line
                          hide-details
                          outlined
                          filled
                          dense
                          rounded
                          class="filtros-trans"
                          v-bind="attrs"
                          v-on="on"
                          @click:append="clearStartDate"
                          :append-icon="startDate ? 'mdi-close' : 'mdi-menu-down'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        no-title
                        scrollable
                        locale="es"
                        @change="startDateMenu = false"
                      />
                    </v-menu>
                  </v-col>


                  <v-col cols="12" sm="2">
                    <v-menu
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedEndDate"
                          label="Fecha fin"
                          single-line
                          hide-details
                          outlined
                          filled
                          dense
                          rounded
                          class="filtros-trans"
                          v-bind="attrs"
                          v-on="on"
                          @click:append="clearEndDate"
                          :append-icon="endDate ? 'mdi-close' : 'mdi-menu-down'"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="maxDate"
                        :min="startDate"
                        no-title
                        scrollable
                        locale="es"
                        @change="endDateMenu = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="1" class="d-flex align-center mt-n5">
                    <v-btn color="primary" small @click="loadPlanilla">
                      Buscar
                      <v-icon right>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="d-flex offset-1  mt-n4">
                    <v-autocomplete
                      v-model="selectedHeaders"
                      :items="headers"
                      label="Columnas Visibles"
                      multiple
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 3" color="primary">
                          <span>{{ item.text }}</span>
                        </v-chip>
                        <span
                          v-else-if="index === 3"
                          class="grey--text caption"
                        >
                          (otras {{ selectedHeaders.length - 3 }}+)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <br />
                <br />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="mb-3" v-if="planillaCaja && planillaCaja.length > 0">
        <v-col class="d-flex justify-end" style="margin-right: 20px;">
          <v-btn color="green" small dark
            @click="exportPDF()">
            <v-icon left>mdi-file-pdf-box</v-icon>
            Descargar
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto" md="12">
          <v-data-table
            :headers="showHeaders"
            :items="planillaCaja"
            class="elevation-0"
          >

            <template v-slot:no-data>
            <div v-if="loading" class="text-center">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
            </div>
            <div v-else>
              Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores<v-icon right>mdi-magnify</v-icon>
            </div>
          </template>


            <template #item.ref_pedido="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    <a
                      href="#"
                      class="text-primary text-decoration-underline"
                      @click.prevent="goDetalle(item)"
                    >
                      {{ item.ref_pedido }}
                    </a>
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.delegacion="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.delegacion }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.cliente="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ item.cliente }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.numero_legal="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ getNumeroLegal(item) }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.bankNacion="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.bankNacion) ? '$'+$formatCurrencyValue(item.bankNacion) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.efectivo="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.efectivo) ? '$'+$formatCurrencyValue(item.efectivo) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.cheque="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.cheque) ? '$'+$formatCurrencyValue(item.cheque) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.tarjetaCredito="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.tarjetaCredito) ? '$'+$formatCurrencyValue(item.tarjetaCredito) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.tarjetaDebito="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.tarjetaDebito) ? '$'+$formatCurrencyValue(item.tarjetaDebito) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.transfDeposito="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.transfDeposito && item.transfDeposito>0) ? '$' + $formatCurrencyValue(item.transfDeposito) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.fecha="{ item }">
              <v-row>
                <v-col>
                  <div class="table-cell-trans text-left">
                    {{ (item.fecha) ? $formatDate(item.fecha) : '' }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template #item.total="{ item }">
              <v-row>
                <v-col>
                  <div
                    class="table-cell-trans text-left"
                    :class="{'text-red': item.total < 0}"
                  >
                    {{ (item.total) ? '$' + $formatCurrencyValue(item.total) : '$0,00' }}
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PlanillaCaja",
  data() {
    return {
      loading: false,
      puntoVenta:"",
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      today: new Date().toISOString().substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
      originalHeaders: [
        { text: "Ref. Pedido", value: "ref_pedido", sortable: false },
        { text: "Delegación", value: "delegacion", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Número Legal", value: "numero_legal", sortable: false },
        { text: "Banco Nación", value: "bankNacion", sortable: false },
        { text: "Efectivo", value: "efectivo", sortable: false },
        { text: "Cheque", value: "cheque", sortable: false },
        { text: "Tarjeta de Crédito", value: "tarjetaCredito", sortable: false },
        { text: "Tarjeta de Débito", value: "tarjetaDebito", sortable: false },
        { text: "Transfer/Depósito", value: "transfDeposito", sortable: false },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Total", value: "total", sortable: false },
      ],
      headers: [],
      selectedHeaders: [],
      planillaCaja: [],
      dataReporte:[],


    };
  },
  computed: {
    //...mapState("transacciones", ["planillaCaja"]),
    formattedStartDate() {
      return this.startDate ? this.$formatDate(this.startDate) : "";
    },
    formattedEndDate() {
      return this.endDate ? this.$formatDate(this.endDate) : "";
    },
    showHeaders() {
      return this.headers.filter(h =>
        this.selectedHeaders.some(sh => sh.value === h.value)
      );
    },
    isDrawerOpen() {
      return this.$store.state.drawer;
    }
  },
  watch: {
    /*startDate(newVal, oldVal) {
      if (!newVal) {
        this.endDate = null;
        return;
      }
      if (oldVal === null) {
        return;
      }
      const start = new Date(newVal);
      start.setDate(start.getDate() + 10);
      let plusTen = start.toISOString().slice(0, 10);

      if (new Date(plusTen) > new Date(this.today)) {
        plusTen = this.today;
      }
      this.endDate = plusTen;
      this.maxDate = plusTen;
    },*/
    isDrawerOpen(newVal) {
      if (newVal) {
        this.headers = this.originalHeaders.filter(h =>
          h.value !== "delegacion" && h.value !== "numero_legal"
        );
      } else {
        this.headers = JSON.parse(JSON.stringify(this.originalHeaders));
        // Reasignamos selectedHeaders con TODAS las columnas
        this.selectedHeaders = JSON.parse(JSON.stringify(this.originalHeaders));
      }
    }
  },
  methods: {
    ocultarMenu(){
      setTimeout(() => {
        this.$hideMenu();
      }, 100);
    },
    clearStartDate() {
      this.startDate = null;
    },
    clearEndDate() {
      this.endDate = null;
    },
    exportPDF() {
      Swal.alertGetInfo("Buscando información");
      let url = "/api/planilla-caja/pdf";


      const userDeleg = (this.$store.state.user.username || "").toUpperCase();

      const queryParams = {
        DocDateFrom: this.startDate || "",
        DocDateTo:   this.endDate   || "",
        U_Deleg:     userDeleg || "",
      };
      const qs = new URLSearchParams(queryParams).toString();
      let formPost = {
        dateFrom: this.startDate,
        dateTo: this.endDate,
        data: this.dataReporte,
      }
      //let datos = this.dataReporte;
      this.$axiosApi.sendRequestPdfLoad(url, "POST", formPost)
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

            Swal.close();
          window.open(url);
        })
        .catch((error) => this.$handleAxiosError(error));


    },
    getNumeroLegal(item) {
      //console.log(item.PointOfIssueCode);
      const pointOfIssue = (item.PointOfIssueCode || "").padStart(5, "0");
      const folio        = (item.FolioNumberFrom || "").toString().padStart(8, "0");

      return `${pointOfIssue}-${folio}`;
    },
    async loadPlanilla() {
          try {
            this.loading = true;
            this.planillaCaja = [];
            this.dataReporte = [];
            this.startDate = this.startDate || this.today;
            this.endDate = this.endDate || this.today;

            const userDeleg = (this.$store.state.user.username || "").toUpperCase();

            let queryParams = {
              DocDateFrom: this.startDate || "",
              DocDateTo:   this.endDate   || "",
              U_Deleg:     userDeleg || ""
            };

            let urlPlanillaCaja ="planillaCaja"
            if(this.$can(["admin_general"]))
            {
              queryParams = {
                DocDateFrom: this.startDate || "",
                DocDateTo:   this.endDate   || "",
                PuntoVenta:  this.puntoVenta || "",
              };
              urlPlanillaCaja ="planillaCajaAdmin"

            }

            const qs = new URLSearchParams(queryParams).toString();

            const response = await this.$axiosApi.getByQuery(urlPlanillaCaja, qs);
            if (response.status !== 200) {
              throw new Error("Error en la respuesta de la API planillaCaja");
            }

            const data = response.data.data; // Array con registros
            this.dataReporte = data;
            // Mapeo de U_MPago => nombre de la columna
            const paymentCols = {
              BANKNACION:          "bankNacion",
              BANKNACION_EFECTIVO: "efectivo",
              BANKNACION_CHEQUE:   "cheque",
              CREDITCARD:          "tarjetaCredito",
              DEBITCARD:           "tarjetaDebito",
              TRANSFER:            "transfDeposito",
              DEPOSIT:             "transfDeposito",
            };

            let planilla = data.map(item => {

              const row = {
                ref_pedido:   item.U_RefPedido   || "",
                delegacion:   item.U_Deleg       || "",
                cliente:      item.CardName      || "",
                numero_legal: "", // si no existe en el JSON, estará vacío
                bankNacion:     0,
                efectivo:       0,
                cheque:         0,
                tarjetaCredito: 0,
                tarjetaDebito:  0,
                transferencia:  0,
                deposito:       0,
                fecha:        item.DocDate       || "",
                total:        item.DocTotal      || 0,
                PointOfIssueCode: item.PointOfIssueCode,
                FolioNumberFrom:  item.FolioNumberFrom,
              };

              const metodoPago = item.U_MPago || "";
              const col = paymentCols[metodoPago];
              if (col) {
                row[col] = item.DocTotal || 0;
              }

              return row;
            });


            this.planillaCaja = planilla;
          } catch (error) {
            console.error("Error al obtener la planilla de caja:", error);
          }






      this.loading = false;
    },
    goDetalle(item) {},

    clearField(field) {
      this.puntoVenta = "";
    },

  },
  created() {
    this.headers = JSON.parse(JSON.stringify(this.originalHeaders));
    this.selectedHeaders = JSON.parse(JSON.stringify(this.headers));
    this.startDate = this.today;
    this.endDate = this.today;
    //this.loadPlanilla();
  },
  mounted() {
    this.ocultarMenu();
  },
};
</script>

<style scoped>
.filtros-trans {
  margin-bottom: 4px;
}
.table-cell-trans {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left !important;
}
.mt-n4 {
  margin-top: -16px !important;
}
.mt-n5 {
  margin-top: -20px !important;
}
.text-red {
  color: red;
  font-weight: bold;
}
</style>
